import AnimatedPage from "../../components/animatedPage/AnimatedPage";

const PrivacyPolicy = () => {
  return (
    <AnimatedPage>
      <div className="xl:px-16 px-1 m-auto max-w-5xl pb-20">
        <h1 className="text-5xl font-extrabold tracking-tight text-slate-900 text-center mt-10">
          Privacy Policy
        </h1>
        <p className="mt-4 leading-7 text-slate-600 text-center text-lg">
          Last updated on November 14th, 2022
        </p>
        <div
          className="relative px-4 sm:px-6 lg:px-8 text-lg text-gray-500 mt-12"
          style={{ lineHeight: "1.7142857" }}
        >
          <div
            className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold
                                 prose-a:text-sky-500 hover:prose-a:text-sky-600"
          >
            <p>
              www.livetakeoff.com (the "Site") is owned and operated by Live
              Takeoff LLC. Live Takeoff LLC can be contacted at:
            </p>

            <h2 className="text-lg font-medium text-black my-4">Purpose</h2>

            <p>
              The purpose of this privacy policy (this "Privacy Policy") is to
              inform users of our Site of the following:
            </p>

            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>The personal data we will collect</li>
              <li>Use of collected data</li>
              <li>The rights of Site user</li>
              <li>The Site's cookie policy</li>
              <li>The Site's cookie policy</li>
            </ul>

            <p className="mt-2">
              This Privacy Policy applies in addition to the terms and
              conditions of our Site.
            </p>

            <h2 className="text-lg font-medium text-black my-4">Consent</h2>
            <p>By using our Site users agree that they consent to:</p>
            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>The conditions set out in this Privacy Policy</li>
              <li>
                The collection, use, and retention of the data listed in this
                Privacy Policy
              </li>
            </ul>

            <h2 className="text-lg font-medium text-black my-4">
              Personal Data We Collect
            </h2>
            <p>
              We only collect data that helps us achieve the purpose set out in
              this Privacy Policy. We will not collect any additional data
              beyond the data listed below without notifying you first.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Data Collected in a Non-Automatic Way
            </h2>
            <p>
              We may also collect the following data when you perform certain
              functions on our Site:
            </p>
            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>Email Address</li>
              <li>Phone Number</li>
              <li>Address</li>
            </ul>

            <p>This data may be collected using the following methods:</p>
            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>Creating the user account</li>
            </ul>

            <h2 className="text-lg font-medium text-black my-4">
              How We Use Personal Data
            </h2>
            <p>
              Data collected on our Site will only be used for the purposes
              specified in this Privacy Policy or indicated on the relevant
              pages of our Site. We will not use your data beyond what we
              disclose in this Privacy Policy.
            </p>
            <p>
              The data we collect when the user performs certain functions may
              be used for the following purposes:
            </p>
            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>
                User account creation to be able to use the Web Application
              </li>
            </ul>

            <h2 className="text-lg font-medium text-black my-4">
              Who We Share Personal Data With
            </h2>
            <h3 className="font-bold text-gray-500 py-2">Employees</h3>
            <p>
              We may disclose user data to any member of our organization who
              reasonably needs access to user data to achieve the purposes set
              out in this Privacy Policy.
            </p>

            <h3 className="font-bold text-gray-500 py-2">Other Disclosures</h3>
            <p>
              We will not sell or share your data with other third parties,
              except in the following cases:
            </p>
            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>If the law requires it</li>
              <li>If it is required for any legal proceeding</li>
              <li>To prove or protect our legal rights</li>
              <li>
                To buyers or potential buyers of this company in the event that
                we seek to sell the company
              </li>
            </ul>

            <p>
              If you follow hyperlinks from our Site to another Site, please
              note that we are not responsible for and have no control over
              their privacy policies and practices.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              How Long We Store Personal Data
            </h2>
            <p>
              User data will be stored until the purpose the data was collected
              for has been achieved.
            </p>
            <p>
              You will be notified if your data is kept for longer than this
              period.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              How We Protect Your Personal Data
            </h2>
            <p>
              In order to protect your security, we use the strongest available
              browser encryption and store all of our data on servers in secure
              facilities. All data is only accessible to our employees. Our
              employees are bound by strict confidentiality agreements and a
              breach of this agreement would result in the employee's
              termination.
            </p>
            <p>
              While we take all reasonable precautions to ensure that user data
              is secure and that users are protected, there always remains the
              risk of harm. The Internet as a whole can be insecure at times and
              therefore we are unable to guarantee the security of user data
              beyond what is reasonably practical.
            </p>

            <h2 className="text-lg font-medium text-black my-4">Children</h2>
            <p>
              The minimum age to use our website is 18 years of age. We do not
              knowingly collect or use personal data from children under 13
              years of age. If we learn that we have collected personal data
              from a child under 13 years of age, the personal data will be
              deleted as soon as possible. If a child under 13 years of age has
              provided us with personal data their parent or guardian may
              contact our privacy officer.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              How to Access, Modify, Delete, or Challenge the Data Collected
            </h2>
            <p>
              If you would like to know if we have collected your personal data,
              how we have used your personal data, if we have disclosed your
              personal data and to who we disclosed your personal data, or if
              you would like your data to be deleted or modified in any way,
              please contact our privacy officer here:
            </p>

            <p>Robeidy Ortiz</p>
            <p>
              <a href="tel:+18555000538">+1 855-500-0538</a>
            </p>
            <p>support@livetakeoff.com</p>
            <p>750 SW 34th St, Suite 209, Fort Lauderdale, FL 33315</p>

            <h2 className="text-lg font-medium text-black my-4">
              Do Not Track Notice
            </h2>
            <p>
              Do Not Track ("DNT") is a privacy preference that you can set in
              certain web browsers. We do not track the users of our Site over
              time and across third party websites and therefore do not respond
              to browser-initiated DNT signals.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              How to Opt-Out of Data Collection, Use or Disclosure
            </h2>
            <p>
              In addition to the method(s) described in the How to Access,
              Modify, Delete, or Challenge the Data Collected section, we
              provide the following specific opt-out methods for the forms of
              collection, use, or disclosure of your personal data specified
              below:
            </p>
            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>
                You can opt-out of the use of your personal data to receive Text
                Messages and/or E-mails. You can opt-out by the user can access
                their profiles and opt-out by turning off the available switch
                or send an email with the request to support@cleantakeoff.com
              </li>
            </ul>

            <h2 className="text-lg font-medium text-black my-4">
              Cookie Policy
            </h2>
            <p>
              A cookie is a small file, stored on a user's hard drive by a
              website. Its purpose is to collect data relating to the user's
              browsing habits. You can choose to be notified each time a cookie
              is transmitted. You can also choose to disable cookies entirely in
              your internet browser, but this may decrease the quality of your
              user experience.
            </p>
            <p>We use the following types of cookies on our Site:</p>
            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>
                Functional cookies are used to remember the selections you make
                on our Site so that your selections are saved for your next
                visits
              </li>
              <li>
                Analytical cookies allow us to improve the design and
                functionality of our Site by collecting data on how you access
                our Site, for example data on the content you access, how long
                you stay on our Site, etc
              </li>
              <li>
                Targeting cookies collect data on how you use the Site and your
                preferences. This allows us to personalize the information you
                see on our Site for you
              </li>
              <li>
                Third-party cookies are created by a website other than ours. We
                may use third-party cookies to achieve the following purposes:
                Monitor user events and navigation for Analytics
              </li>
            </ul>

            <h2 className="text-lg font-medium text-black my-4">
              Modifications
            </h2>
            <p>
              This Privacy Policy may be amended from time to time in order to
              maintain compliance with the law and to reflect any changes to our
              data collection process. When we amend this Privacy Policy we will
              update the "Effective Date" at the top of this Privacy Policy. We
              recommend that our users periodically review our Privacy Policy to
              ensure that they are notified of any updates. If necessary, we may
              notify users by email of changes to this Privacy Policy.
            </p>

            <h2 className="font-bold mt-6 text-black">Contacting us</h2>
            <p>
              If you have any questions about this Policy, please contact us by
              email at{" "}
              <a
                href="mailto:support@livetakeoff.com"
                className="text-sky-500 font-medium"
              >
                support@livetakeoff.com
              </a>
              <p></p>.
            </p>
            <p>Robeidy Ortiz</p>
            <a href="tel:+18555000538">+1 855-500-0538</a>
            <p>750 SW 34th St, Suite 209, Fort Lauderdale, FL 33315</p>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default PrivacyPolicy;
