import AnimatedPage from "../../components/animatedPage/AnimatedPage";

const TermsAndConditions = () => {
  return (
    <AnimatedPage>
      <div className="xl:px-16 px-1 m-auto max-w-5xl pb-20">
        <h1 className="text-5xl font-extrabold tracking-tight text-slate-900 text-center mt-10">
          Terms And Conditions
        </h1>
        <p className="mt-4 text-lg leading-7 text-slate-600 text-center">
          Last updated on November 14th, 2022
        </p>
        <div
          className="relative px-4 sm:px-6 lg:px-8 text-lg text-gray-500 mt-12"
          style={{ lineHeight: "1.7142857" }}
        >
          <div
            className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold
                                 prose-a:text-sky-500 hover:prose-a:text-sky-600"
          >
            <p>
              These terms and conditions (the "Terms and Conditions") govern the
              use of www.livetakeoff.com (the "Site"). This Site is owned and
              operated by Live Takeoff LLC. This Site is a web application.
            </p>
            <p>
              By using this Site, you indicate that you have read and understand
              these Terms and Conditions and agree to abide by them at all
              times.
            </p>
            <h2 className="text-lg font-medium text-black my-4">
              Intellectual Property
            </h2>
            <p>
              All content published and made available on our Site is the
              property of Live Takeoff LLC and the Site's creators. This
              includes, but is not limited to images, text, logos, documents,
              downloadable files and anything that contributes to the
              composition of our Site.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Age Restrictions
            </h2>
            <p>
              The minimum age to use our Site is 18 years old. By using this
              Site, users agree that they are over 18 years old. We do not
              assume any legal responsibility for false statements about age.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Acceptable Use
            </h2>
            <p>
              As a user of our Site, you agree to use our Site legally, not to
              use our Site for illegal purposes, and not to:
            </p>

            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>Harass or mistreat other users of our Site</li>
              <li>Violate the rights of other users of our Site</li>
              <li>
                Violate the intellectual property rights of the Site owners or
                any third party to the Site
              </li>
              <li>Hack into the account of another user of the Site</li>
              <li>Act in any way that could be considered fraudulent</li>
              <li>
                Post any material that may be deemed inappropriate or offensive
              </li>
              <li>Generate estimates to share with competitors</li>
              <li>
                Take snapshots of the Web Application to save, print or share
              </li>
            </ul>

            <p>
              If we believe you are using our Site illegally or in a manner that
              violates these Terms and Conditions, we reserve the right to
              limit, suspend or terminate your access to our Site. We also
              reserve the right to take any legal steps necessary to prevent you
              from accessing our Site.
            </p>

            <h2 className="text-lg font-medium text-black my-4">Accounts</h2>
            <p>
              When you create an account on our Site, you agree to the
              following:
            </p>

            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>
                You are solely responsible for your account and the security and
                privacy of your account, including passwords or sensitive
                information attached to that account and
              </li>
              <li>
                All personal information you provide to us through your account
                is up to date, accurate, and truthful and that you will update
                your personal information if it changes
              </li>
            </ul>

            <p>
              We reserve the right to suspend or terminate your account if you
              are using our Site illegally or if you violate these Terms and
              Conditions.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Sale of Services
            </h2>
            <p>
              These Terms and Conditions govern the sale of services available
              on our Site.
            </p>

            <p>The following services are available on our Site:</p>

            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>Aircraft Interior and Exterior Detailing Services</li>
            </ul>

            <p>
              The services will be paid for in full upon the completion of the
              service.
            </p>

            <p>
              These Terms and Conditions apply to all the services that are
              displayed on our Site at the time you access it. All information,
              descriptions, or images that we provide about our services are as
              accurate as possible. However, we are not legally bound by such
              information, descriptions, or images as we cannot guarantee the
              accuracy of all services we provide. You agree to purchase
              services from our Site at your own risk.
            </p>

            <p>
              We reserve the right to modify, reject or cancel your order
              whenever it becomes necessary. If we cancel your order and have
              already processed your payment, we will give you a refund equal to
              the amount you paid. You agree that it is your responsibility to
              monitor your payment instrument to verify receipt of any refund.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Third Party Goods and Services
            </h2>
            <p>
              Our Site may offer goods and services from third parties. We
              cannot guarantee the quality or accuracy of goods and services
              made available by third parties on our Site.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Subscriptions
            </h2>
            <p>
              Your subscription automatically renews and you will be
              automatically billed until we receive notification that you want
              to cancel the subscription.
            </p>

            <h2 className="text-lg font-medium text-black my-4">Payments</h2>
            <p>We accept the following payment methods on our Site:</p>

            <ul className="pl-5 py-4 list-disc list-inside space-y-4">
              <li>Credit Card</li>
              <li>Debit</li>
              <li>Direct Debit</li>
              <li>Paper Check</li>
              <li>Wire Transfers</li>
              <li>ACH</li>
            </ul>

            <p>
              When you provide us with your payment information, you authorize
              our use of and access to the payment instrument you have chosen to
              use. By providing us with your payment information, you authorize
              us to charge the amount due to this payment instrument.
            </p>

            <p>
              If we believe your payment has violated any law or these Terms and
              Conditions, we reserve the right to cancel or reverse your
              transaction.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Consumer Protection Law
            </h2>
            <p>
              Where any consumer protection legislation in your jurisdiction
              applies and cannot be excluded, these Terms and Conditions will
              not limit your legal rights and remedies under that legislation.
              These Terms and Conditions will be read subject to the mandatory
              provisions of that legislation. If there is a conflict between
              these Terms and Conditions and that legislation, the mandatory
              provisions of the legislation will apply.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Limitation of Liability
            </h2>
            <p>
              Live Takeoff LLC and our directors, officers, agents, employees,
              subsidiaries, and affiliates will not be liable for any actions,
              claims, losses, damages, liabilities and expenses including legal
              fees from your use of the Site.
            </p>

            <h2 className="text-lg font-medium text-black my-4">Indemnity</h2>
            <p>
              Except where prohibited by law, by using this Site you indemnify
              and hold harmless Live Takeoff LLC and our directors, officers,
              agents, employees, subsidiaries, and affiliates from any actions,
              claims, losses, damages, liabilities and expenses including legal
              fees arising out of your use of our Site or your violation of
              these Terms and Conditions.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Applicable Law
            </h2>
            <p>
              These Terms and Conditions are governed by the laws of the State
              of Florida.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Severability
            </h2>
            <p>
              If at any time any of the provisions set forth in these Terms and
              Conditions are found to be inconsistent or invalid under
              applicable laws, those provisions will be deemed void and will be
              removed from these Terms and Conditions. All other provisions will
              not be affected by the removal and the rest of these Terms and
              Conditions will still be considered valid.
            </p>

            <h2 className="text-lg font-medium text-black my-4">Changes</h2>
            <p>
              These Terms and Conditions may be amended from time to time in
              order to maintain compliance with the law and to reflect any
              changes to the way we operate our Site and the way we expect users
              to behave on our Site. We will notify users by email of changes to
              these Terms and Conditions or post a notice on our Site.
            </p>

            <h2 className="text-lg font-medium text-black my-4">
              Contact Details
            </h2>
            <p>
              Please contact us if you have any questions or concerns. Our
              contact details are as follows:
            </p>

            <p>
              <a href="tel:+18555000538">+1 855-500-0538</a>
            </p>
            <a
              href="mailto:support@livetakeoff.com"
              className="text-sky-500 font-medium"
            >
              support@livetakeoff.com
            </a>
            <p>750 SW 34th St, Suite 209, Fort Lauderdale, FL 33315</p>
            <p>
              You can also contact us through the feedback form available on our
              Site.
            </p>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default TermsAndConditions;
